import React from 'react';
import defaultActions from '../../actions/default';
import { createClientMessage } from 'react-chatbot-kit';
import axios from '../../utils/axios';
import ReactMarkdown from 'react-markdown';
import LinkComponent from '../LinkComponent';
import { v4 as uuid } from 'uuid';
import { getPreguntasFrecuentesURL } from '../../utils/axios';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  async function handleResponse(option) {
    try {
      const response = await axios.get(`/api/menu?menu=${option.id_menu}`);

      const { data } = response;
      const tieneSubMenu = data.questions?.length > 0;
      const totalQuestions = tieneSubMenu ? data.questions.length : null;

      const message = createChatBotMessage(
        <ReactMarkdown key={uuid()} components={{ a: LinkComponent }}>
          {data.defaultMessage === '' && totalQuestions > 0
            ? 'Elige una de las siguientes opciones'
            : data.defaultMessage}
        </ReactMarkdown>,
        tieneSubMenu
          ? {
              widget: 'buttons',
              payload: {
                options: data.questions,
              },
            }
          : {
              widget: 'back',
            }
      );

      setState((prev) => {
        return {
          ...prev,
          messages: [...prev.messages, message],
        };
      });
    } catch (error) {
      const botMessage = createChatBotMessage('Ocurrió un error');
      const botMessage2 = createChatBotMessage(
        <p>
          Puedes revisar las{' '}
          <a href={getPreguntasFrecuentesURL} target='_blank' rel='noreferrer'>
            preguntas frecuentes
          </a>
        </p>,
        {
          widget: 'back',
        }
      );
      setState((prev) => {
        return {
          ...prev,
          messages: [...prev.messages, botMessage, botMessage2],
        };
      });
    }
  }

  // Mensaje luego de prediccion
  // Voy a recibir la respuesta de la api
  function handleClientMessageResponse(data) {
    const tieneSubMenu = data.questions && data.questions.length > 0;

    const totalQuestions = tieneSubMenu ? data.questions.length : null;

    const botMessage = createChatBotMessage(
      <ReactMarkdown key={uuid()} components={{ a: LinkComponent }}>
        {data.defaultMessage === '' && totalQuestions > 0
          ? 'Elige una de las siguientes opciones'
          : data.defaultMessage}
      </ReactMarkdown>,
      tieneSubMenu
        ? {
            widget: 'buttons',
            payload: {
              options: data.questions,
            },
          }
        : {
            widget: 'back',
          }
    );

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, botMessage],
      };
    });
  }

  // Mensajes del cliente
  const clientMessage = (text) => {
    const message = createClientMessage(text);

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, message],
      };
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleClientMessageResponse,
            clientMessage,
            handleResponse,
            ...defaultActions(createChatBotMessage, setState),
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
