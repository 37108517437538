import { useContext } from 'react';
import { Context } from '../../components/providers/StateProvider';
import { FaTimes } from 'react-icons/fa';
import styles from './Header.module.css';
import { FaRobot } from 'react-icons/fa';

const Header = () => {
  const { setShowAsistente } = useContext(Context);

  return (
    <div className={styles.container}>
      <FaRobot size='20px' style={{ color: '#fff' }} />
      <p>Asistente virtual</p>

      <button
        className={styles.close_button_container}
        aria-label='Cerrar asistente'
        onClick={(prev) => setShowAsistente(!prev)}
      >
        <FaTimes />
      </button>
    </div>
  );
};

export default Header;
