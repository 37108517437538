import { createChatBotMessage } from 'react-chatbot-kit';
import Header from '../Header/Header';
import BotAvatar from '../Avatar/BotAvatar';
import Buttons from '../Buttons/Buttons';
import BackComponent from '../BackComponent/BackComponent';
import { getPreguntasFrecuentesURL } from '../../utils/axios';

function getConfig(initialMenu) {
  const hayMenues = initialMenu?.length > 0;

  return {
    initialMessages: hayMenues
      ? [
          createChatBotMessage(`Hola!, ¿en qué te puedo ayudar?`),
          createChatBotMessage(
            'Hace click sobre el tema que quieras consultar',
            {
              widget: 'buttons',
            }
          ),
        ]
      : [
          createChatBotMessage(
            'En estos momentos el asistente virtual se encuentra fuera de servicio'
          ),
          createChatBotMessage(
            <p>
              Puedes revisar las{' '}
              <a
                href={getPreguntasFrecuentesURL}
                target='_blank'
                rel='noreferrer'
              >
                preguntas frecuentes
              </a>
            </p>
          ),
        ],
    lang: 'es',
    customStyles: {
      botMessageBox: {
        backgroundColor: '#02545e',
      },
    },
    widgets: [
      {
        widgetName: 'buttons',
        widgetFunc: (props) => <Buttons {...props} initialMenu={initialMenu} />,
      },
      {
        widgetName: 'back',
        widgetFunc: (props) => (
          <BackComponent {...props} isWorking={initialMenu != null} />
        ),
      },
    ],
    customComponents: {
      header: function (props) {
        return <Header {...props} />;
      },
      botAvatar: function (props) {
        return <BotAvatar {...props} isWorking={initialMenu != null} />;
      },
    },
  };
}

export default getConfig;
