import { getPreguntasFrecuentesURL } from '../utils/axios';

export default function defaultActions(createChatBotMessage, setState) {
  // Mensaje de bienvenida
  const handleWelcome = async () => {
    const botMessage = createChatBotMessage(
      'Hace click sobre el tema que quieras consultar',
      {
        widget: 'buttons',
      }
    );

    setState((prev) => {
      return {
        ...prev,
        messages: [prev.messages[0], botMessage],
      };
    });
  };

  // Cuando no entiendo la pregunta, lo mando aca
  const handleDefaultAnswer = () => {
    const botMessage1 = createChatBotMessage('No logro entender tu pregunta');
    const botMessage2 = createChatBotMessage(
      <p>
        Puedes revisar las{' '}
        <a href={getPreguntasFrecuentesURL} target='_blank' rel='noreferrer'>
          preguntas frecuentes
        </a>
      </p>,
      {
        widget: 'back',
      }
    );

    setState((prev) => {
      return {
        ...prev,
        messages: [...prev.messages, botMessage1, botMessage2],
      };
    });
  };

  return {
    handleWelcome,
    handleDefaultAnswer,
  };
}
