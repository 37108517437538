import StateProvider from './components/providers/StateProvider';
import ChatBotComponent from './components/ChatBotComponent';

const App = () => {
  return (
    <StateProvider>
      <ChatBotComponent />
    </StateProvider>
  );
};

export default App;
