import axios from 'axios';

export const getBackendURL =
  window._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL; // eslint-disable-line

export const getPreguntasFrecuentesURL =
  window._env_?.REACT_APP_URL_PREGUNTAS_FRECUENTES ||
  process.env.REACT_APP_URL_PREGUNTAS_FRECUENTES;

export default axios.create({
  baseURL: getBackendURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});
