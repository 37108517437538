import Chatbot from 'react-chatbot-kit';
import getConfig from './bot/config';
import MessageParser from './bot/MessageParser';
import ActionProvider from './bot/ActionProvider';
import { useContext, useEffect, useState } from 'react';
import { Context } from './providers/StateProvider';
import { FaRobot } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axios from '../utils/axios';
import 'react-tooltip/dist/react-tooltip.css';

const ChatBotComponent = () => {
  const { setShowAsistente, showAsistente } = useContext(Context);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getInitialMenu();
  }, []);

  async function getInitialMenu() {
    try {
      const response = await axios.get('/api/menu?menu=0');
      setMenu(response.data.questions);
    } catch (error) {
      setMenu([]);
    }
  }

  return !showAsistente ? (
    <>
      <button
        onClick={() => setShowAsistente((prev) => !prev)}
        className='first-button-container'
        aria-label='Iniciar asistente'
      >
        <FaRobot size={24} />
      </button>
      <ReactTooltip anchorSelect='.first-button-container' place='top'>
        Iniciar asistente virtual
      </ReactTooltip>
    </>
  ) : (
    <Chatbot
      config={getConfig(menu)}
      messageParser={MessageParser}
      actionProvider={ActionProvider}
      placeholderText='Escribe tu mensaje aquí...'
    />
  );
};

export default ChatBotComponent;
