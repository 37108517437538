import { FaLaugh, FaSadCry } from 'react-icons/fa';
import styles from './BotAvatar.module.css';

const BotAvatar = ({ isWorking }) => {
  return (
    <div className={styles.container}>
      {isWorking ? <FaLaugh /> : <FaSadCry />}
    </div>
  );
};

export default BotAvatar;
