import styles from './Buttons.module.css';
import { v4 as uuid } from 'uuid';

function Buttons(props) {
  const { payload, initialMenu } = props;

  return (
    <div className={styles.button_container}>
      {payload ? (
        <>
          {payload.options.map((question) => {
            return (
              <button
                key={uuid()}
                className={styles.button}
                aria-label={question.title}
                onClick={() => {
                  props.actionProvider.handleResponse(question);
                  props.actionProvider.clientMessage(question.title);
                }}
              >
                {question.title}
              </button>
            );
          })}
          {props.state?.messages?.length > 2 && initialMenu !== null && (
            <button
              className={styles.button}
              onClick={props.actionProvider.handleWelcome}
            >
              Volver
            </button>
          )}
        </>
      ) : (
        initialMenu.map((question) => (
          <button
            key={uuid()}
            className={styles.button}
            aria-label={question.title}
            onClick={() => {
              props.actionProvider.handleResponse(question);
              props.actionProvider.clientMessage(question.title);
            }}
          >
            {question.title}
          </button>
        ))
      )}
    </div>
  );
}

export default Buttons;
