import styles from '../Buttons/Buttons.module.css';

function BackComponent(props) {
  if (!props.isWorking) return null;

  return (
    <div className={styles.button_container}>
      <button
        className={styles.button}
        onClick={props.actionProvider.handleWelcome}
        aria-label='Volver'
      >
        Volver
      </button>
    </div>
  );
}

export default BackComponent;
